import React from 'react'
import Image from 'next/image'
import StarRatingComponent from 'react-star-rating-component'
import Link from 'next/link'
import { Container } from '../Container'
import { Button } from '../Button'
import { useQueryParam } from '../../hooks/useQueryParam'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faStarHalfAlt } from '@fortawesome/free-solid-svg-icons'
import backgroundImage from '../../../public/img/new_tech_2.jpg'

type Props = {
  image: any
  title: string
  subheading: string | React.ReactElement
  callToActionButtonText: string
  phoneNumber?: string
  phoneNumberTxt?: string
  rating: string
  reviewCount: number
  showImage?: boolean
  showRatings?: boolean
  buttonColor?: string
  showOverlay?: boolean
}

export const TitleSection: React.FC<Props> = ({
  phoneNumberTxt,
  phoneNumber,
  title,
  image,
  subheading,
  callToActionButtonText,
  rating,
  reviewCount,
  showImage = true,
  showRatings,
  buttonColor,
  showOverlay = false,
}) => {
  const query = useQueryParam()
  return (
    <section className="py-0 text-white">
      <div
        className="flex flex-col justify-center align-center py-8"
        style={{ minHeight: '60vh', position: 'relative', width: '100%' }}
      >
        {showImage && (
          <Image
            src={image ?? backgroundImage}
            layout={'fill'}
            priority={true}
            alt="NuBrakes Mobile Repair Services"
            objectFit="cover"
            objectPosition="center center"
          />
        )}
        {showOverlay && (
          <div
            style={{
              backgroundColor: 'rgba(0,0,0,.75)',
              position: 'absolute',
              width: '100%',
              height: '100%',
              zIndex: 1,
              top: 0,
              left: 0,
            }}
          />
        )}
        <div style={{ zIndex: 1 }}>
          <Container className="text-center text-white">
            <h1 className="title">{title}</h1>
            <p className="subheading text-white">{subheading}</p>
            {callToActionButtonText && (
              <div>
                <Link href={{ pathname: '/get-quote/', query }} passHref={true}>
                  <a>
                    <Button
                      style={{
                        paddingTop: 8,
                        paddingBottom: 8,
                        paddingLeft: 25,
                        paddingRight: 25,
                        backgroundColor: buttonColor ?? '#00a1ff',
                      }}
                    >
                      {callToActionButtonText}
                    </Button>
                  </a>
                </Link>
              </div>
            )}
            {phoneNumber && (
              <p className="subheading pt-4 text-white">
                Or Call Our Brake Team Now At{' '}
                <a style={{ color: buttonColor ?? '#00a1ff' }} href={`tel:${phoneNumber}`}>
                  {phoneNumberTxt}
                </a>
              </p>
            )}
            {showRatings && (
              <div className="pt-4 text-white">
                <StarRatingComponent
                  name={'rating'} /* name of the radio input, it is required */
                  value={4.84} /* number of selected icon (`0` - none, `1` - first) */
                  starCount={5} /* number of icons in rating, default `5` */
                  starColor={'gold'} /* color of selected icons, default `#ffb400` */
                  editing={false} /* is component available for editing, default `true` */
                  renderStarIcon={() => {
                    return (
                      <span>
                        <FontAwesomeIcon icon={faStar} color="gold" />
                      </span>
                    )
                  }}
                  renderStarIconHalf={() => {
                    return (
                      <span>
                        <FontAwesomeIcon icon={faStarHalfAlt} color="gold" />
                      </span>
                    )
                  }}
                />
                <p className="subheading text-white">
                  {`NuBrakes is rated ${rating}/5 by `}
                  <Link href={{ pathname: '/reviews/', query }} prefetch={false}>
                    <a
                      style={{ color: buttonColor ?? '#00a1ff', textDecoration: 'underline' }}
                    >{`${reviewCount} brake repair customers`}</a>
                  </Link>
                </p>
              </div>
            )}
          </Container>
        </div>
      </div>
    </section>
  )
}

export const BuilderTitleSection = (props) => (
  <TitleSection
    rating={props.builderState?.state?.rating}
    reviewCount={props.builderState?.state?.reviewCount}
    {...props}
  />
)
