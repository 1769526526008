import * as React from 'react'
import { Container } from '../Container'
import { FAQ } from '../FAQSection'

type Props = {
  title: string
  description: string | React.ReactElement
  faqs: { question: string; answer: string | React.ReactElement }[]
}
export const FAQSection: React.FC<Props> = ({ title, description, faqs }) => {
  return (
    <section className="text-center" style={{ backgroundColor: '#ffeee7' }}>
      {/*language=SCSS*/}
      <style jsx>{`
        .title {
          font-size: 40px;
          color: #000000;
          font-weight: 700;
        }

        .description {
          font-size: 18px;
          color: #7c7c7c;
          font-weight: 400;
        }
      `}</style>
      <Container>
        <h2 className="title">{title}</h2>
        <p className="description">{description}</p>
        <div className="grid md:grid-cols-2 gap-4">
          {faqs.map((value, index) => (
            <FAQ key={index} question={value.question}>
              {value.answer}
            </FAQ>
          ))}
        </div>
      </Container>
    </section>
  )
}
