import * as React from 'react'
import Image from 'next/image'
import { Container } from './Container'

import nbc from '../../public/img/nbc-logo@grayscale-1024x266.png'
import insiderLogo from '/public/img/business-insider-logo.png'
import afterMarket from '/public/img/aftermarket-news-logo.png'
import ratchet from '/public/img/ratchet-wrench-logo@grayscale.png'

export const FeatureBySection: React.FC = () => {
  return (
    <Container className="max-w-full py-5 bg-white px-0">
      <div className="grid grid-cols-2 text-center gap-4 md:grid-cols-5">
        <div className={'col-span-2 md:col-span-1 mb-2 md:mb-0'}>
          <p style={{ fontSize: 28, fontWeight: 700 }}>Featured By</p>
        </div>
        <div style={{ height: 40, width: 160 }}>
          <Image className={'mx-auto'} src={nbc} alt={'NBC Logo'} />
        </div>
        <div style={{ height: 40, width: 140 }}>
          <Image className={'mx-auto'} src={insiderLogo} alt={'Business Insider Logo'} />
        </div>
        <div style={{ height: 40, width: 160 }}>
          <Image src={afterMarket} alt="After Market Image Logo" />
        </div>
        <div style={{ height: 40, width: 140 }}>
          <Image className={'mx-auto'} src={ratchet} alt={'Ratchet + Wrench Logo'} />
        </div>
      </div>
    </Container>
  )
}
